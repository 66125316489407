import React from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginClient from "./pages/auth/ClientLogin";
import OfficerLogin from "./pages/auth/OfficerLogin";
import AuthenticationLayout from "./layouts/AuthenticationLayout";

import ClientList from "./pages/officers/clients/ClientList";
import ClientProfile from "./pages/officers/clients/ClientProfile";
import OfficerList from "./pages/officers/officers/OfficerList";
import OfficerProfile from "./pages/officers/officers/OfficerProfile";
import Reports from "./pages/officers/reports/Reports";
import OfficersLayout from "./layouts/officers/OfficersLayout";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";

import MainLayout from "./layouts/MainLayout";
import Home from 'src/pages/players/1.Main/Home';
import Profile from 'src/pages/players/1.Main/Profile';
import EmptyLayout from "./layouts/EmptyLayout";
import EvaluationQuestionnaire from 'src/pages/players/1.Main/FirstQuestionnaire';
import GamePlay from "./pages/players/1.Main/GameplayBuild";
import PasswordReset from 'src/pages/auth/PasswordReset';

import VictimImpact from 'src/pages/players/2.VictimImpact/VictimImpact';
import TypesOfVictims1 from 'src/pages/players/2.VictimImpact/TypesOfVictims/TypesOfVictims1';
import TypesOfVictims2 from 'src/pages/players/2.VictimImpact/TypesOfVictims/TypesOfVictims2';
import TypesOfHurts1 from 'src/pages/players/2.VictimImpact/TypesOfHurts/TypesOfHurts1';
import TypesOfHurts2 from 'src/pages/players/2.VictimImpact/TypesOfHurts/TypesOfHurts2';
import MakingAmends1 from 'src/pages/players/2.VictimImpact/MakingAmends/MakingAmends1';
import MakingAmends2 from 'src/pages/players/2.VictimImpact/MakingAmends/MakingAmends2';

import LawAndOrder from 'src/pages/players/3.LawAndOrder/LawAndOrder';
import TypesOfSentencing from 'src/pages/players/3.LawAndOrder/TypesOfSentencing';
import CostOfCrime1 from 'src/pages/players/3.LawAndOrder/CostOfCrime/CostOfCrime1';
import CostOfCrime2 from 'src/pages/players/3.LawAndOrder/CostOfCrime/CostOfCrime2';
import FinalQuestionnaire from 'src/pages/players/4.EvaluationQuestionnaire/FinalQuestionnaire';
import GameReflection from "./pages/players/3.LawAndOrder/GameReflection";
import SummarySheet from 'src/pages/players/3.LawAndOrder/SummarySheet';

import ClientForm from "./pages/officers/clients/ClientForm";
import OfficerForm from "./pages/officers/officers/OfficerForm";
import Selection from "./pages/players/3.LawAndOrder/Selection";
import Question from "./pages/players/2.VictimImpact/TypesOfHurts/Question";

function App() {

    // setInterval(() => console.clear(), 10000);

    return (
        <Routes>
            <Route path="/" element={<AuthenticationLayout />}>
                {/* public routes */}
                <Route index element={<Navigate to='/client-login' />} />
                <Route path="client-login" element={<LoginClient />} />
                <Route path="officer-login" element={<OfficerLogin />} />
            </Route>
            <Route path="/testing" element={<TypesOfVictims2 />}/>
            <Route element={<PersistLogin />}>
                <Route element={<RequireAuth userType="Officer" />}>
                    <Route path="/officers/" element={<OfficersLayout />}>
                        <Route path="clients" element={<ClientList />} />
                        <Route path="clients/create-new-account" element={<ClientForm createNew={true} action="CREATE" buttonText="CREATE ACCOUNT"/>} />
                        <Route path="clients/:clientId" element={<ClientProfile />} />
                        <Route path="officers" element={<OfficerList />} />
                        <Route path="officers/create-new-account" element={<OfficerForm createNew={true} action="CREATE" buttonText="CREATE ACCOUNT"/>} />
                        <Route path="officers/:officerId" element={<OfficerProfile />} />
                        <Route path="reports" element={<Reports />} />
                    </Route>
                </Route>
                <Route element={<RequireAuth userType="Player" />}>
                    <Route path="/players/">
                        <Route path="evaluation" element={<EvaluationQuestionnaire />} />
                        {/* <Route element={<MainLayout user={0} footer={true} gameProgress={"VI"} padding={3}/>}> */}
                        <Route element={<MainLayout user={0} footer={true} padding={3}/>}>
                            <Route path="home" element={<Home />} />
                            <Route path="profile" element={<Profile />} />
                        </Route>
                        <Route element={<MainLayout user={0} footer={true} padding={0}/>}>
                            <Route path='final-evaluation' element={<FinalQuestionnaire/>} />
                        </Route>
                        <Route path="gameplay" element={<GamePlay game_name={"LO1"}/>} />
                        <Route path="victim-impact/" element={<MainLayout user={0} footer={true} padding={0}/>}>
                        {/* <Route path="victim-impact/" element={<MainLayout user={0} footer={true} gameProgress={"VI"} padding={0}/>}> */}
                            <Route path='' element={<VictimImpact/>} />
                            <Route path='types-of-victims/1' element={<TypesOfVictims1/>} />
                            <Route path='types-of-victims/2' element={<TypesOfVictims2/>} />
                            <Route path='types-of-hurts/1' element={<TypesOfHurts1/>} />
                            <Route path='types-of-hurts/2' element={<TypesOfHurts2/>} />
                            <Route path='making-amends/1' element={<MakingAmends1/>} />
                            <Route path='making-amends/2' element={<MakingAmends2/>} />
                            <Route path="play" element={<GamePlay game_name={"VI"}/>} />
                        </Route>
                    
                        <Route path="law-and-order/" element={<MainLayout user={0} footer={true} padding={0}/>}>
                        {/* <Route path="law-and-order/" element={<MainLayout user={0} footer={true} gameProgress={"LO"} padding={0}/>}> */}
                            <Route path='' element={<LawAndOrder/>} />
                            <Route path='types-of-sentencing' element={<TypesOfSentencing/>} />
                            <Route path='cost-of-crime/1' element={<CostOfCrime1/>} />
                            <Route path='cost-of-crime/2' element={<CostOfCrime2/>} />
                            <Route path="play" element={<Selection/>} />
                            <Route path="play/LO1" element={<GamePlay game_name={"LO1"}/>} />
                            <Route path="play/LO2" element={<GamePlay game_name={"LO2"}/>} />
                            <Route path="play/LO3" element={<GamePlay game_name={"LO3"}/>} />
                            <Route path="play/LO4" element={<GamePlay game_name={"LO4"}/>} />
                            <Route path="play/LO5" element={<GamePlay game_name={"LO5"}/>} />
                            <Route path="play/LO6" element={<GamePlay game_name={"LO6"}/>} />
                            <Route path="play/LO7" element={<GamePlay game_name={"LO7"}/>} />
                            <Route path="play/LO8" element={<GamePlay game_name={"LO8"}/>} />
                            <Route path="play/LO9" element={<GamePlay game_name={"LO9"}/>} />
                            <Route path="play/LO10" element={<GamePlay game_name={"LO10"}/>} />
                        </Route>

                        <Route path='law-and-order/scene-selection' element={<Selection/>} />
                        <Route path='game-reflection' element={<GameReflection/>}/>
                        <Route path='summary-sheet' element={<SummarySheet/>}/>
                        
                    </Route>

                </Route>
            </Route>
            <Route path="/password/reset" element={<PasswordReset/>}/>
        </Routes>
    );
}

export default App;
