import React, { useState, useEffect } from 'react'
import {
    Button,
    Box,
    Container,
    Divider,
    Typography
} from "@mui/material";

const Selection = () => {

    const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
    const [ screenIsSmall, setScreenIsSmall ] = useState(window.screen.width < 960);
    const [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait');

    useEffect(()=>{
        const reloadCount = sessionStorage.getItem('reloadCount');
        if (reloadCount != null) {
            sessionStorage.removeItem('reloadCount');
        }
    }, [])

    useEffect(() => (
        window.addEventListener('resize', () => {
            setScreenIsSmall(window.screen.width < 960);
            setOrientation(isLandscape() ? 'landscape' : 'portrait');
        }),
        () => window.removeEventListener('resize', () => {
            setScreenIsSmall(window.screen.width < 960);
            setOrientation(isLandscape() ? 'landscape' : 'portrait')
        })
    ))

    if (orientation == 'landscape') {
        return (
            <Container
                maxWidth={false}
                sx={{background: '#001C50', width: '100%', height: '100vh', overflow:'hidden'}}>
    
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <Typography
                        sx={{
                            height:'20vh',
                            fontSize:{xs: '35pt', md: '55pt'},
                            color: '#FFFFFF'
                        }}
                    >
                        Law & Order
                    </Typography>
                </Box>
    
                <Box
                    sx={styles.headerBox}
                >
                    Choose scenario number
                </Box>
                <Box sx={{display:'flex', flexDirection:'row', justifyContent: 'space-between', mb:'15px'}}>
                    <Box sx={styles.whiteBox}>
                        <Button sx={styles.text} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO1"
                        }} >
                            L1
                        </Button>
                    </Box>
                    <Box sx={styles.whiteBox}>
                        <Button sx={styles.text} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO2"
                        }}>
                            L2
                        </Button>
                    </Box>
                    <Box sx={styles.whiteBox}>
                        <Button sx={styles.text} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO3"
                        }}>
                            L3
                        </Button>
                    </Box>
                    <Box sx={styles.whiteBox}>
                    <Button sx={styles.text} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO4"
                        }}>
                            L4
                        </Button>
                    </Box>
                    <Box
                        sx={{height: '24vh', width: '20%', background: '#FFFFFF', borderRadius:'15px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                        <Button sx={styles.text} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO5"
                        }}>
                            L5
                        </Button>
                    </Box>
                </Box>
                <Box sx={{display:'flex', flexDirection:'row', justifyContent: 'space-between'}}>
                <Box sx={styles.whiteBox}>
                    <Button sx={styles.text} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO6"
                        }}>
                            L6
                        </Button>
                    </Box>
                    <Box sx={styles.whiteBox}>
                        <Button sx={styles.text} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO7"
                        }}>
                            L7
                        </Button>
                    </Box>
                    <Box sx={styles.whiteBox}>
                        <Button sx={styles.text} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO8"
                        }}>
                            L8
                        </Button>
                    </Box>
                    <Box sx={styles.whiteBox}>
                        <Button sx={styles.text} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO9"
                        }}>
                            L9
                        </Button>
                    </Box>
                    <Box
                        sx={{height: '24vh', width: '20%', background: '#FFFFFF', borderRadius: '15px',  display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                        <Button sx={styles.text} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO10"
                        }}>
                            L10
                        </Button>
                    </Box>
                </Box>
    
                <Box sx={{ background:'#001C50', display:'flex', flexDirection:'row', justifyContent: 'space-between'}}>
                </Box>
    
            </Container>
        )
    } else {
        return (
            <Container
                maxWidth={false}
                sx={{background: '#001C50', width: '100%', height: '100vh', overflow:'hidden'}}>
    
                <Box sx={{
                    height:'25vh',
                    textAlign:'center',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <Typography
                        sx={{
                            fontSize: '35pt',
                            color: '#FFFFFF',
                            textAlign: 'center'
                        }}
                    >
                        Law & Order
                    </Typography>
                </Box>
    
                <Box
                    sx={styles.headerBoxPortrait}
                >
                    Choose scenario number
                </Box>
                <Box sx={{display:'flex', flexDirection:'row', justifyContent: 'space-between', mb:'15px'}}>
                    <Box sx={styles.whiteBoxPortrait}>
                        <Button sx={styles.textPortrait} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO1"
                        }} >
                            L1
                        </Button>
                    </Box>
                    <Box sx={styles.whiteBoxPortrait}>
                        <Button sx={styles.textPortrait} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO2"
                        }}>
                            L2
                        </Button>
                    </Box>
                    <Box sx={styles.whiteBoxPortrait}>
                        <Button sx={styles.textPortrait} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO3"
                        }}>
                            L3
                        </Button>
                    </Box>
                    <Box sx={styles.whiteBoxPortrait}>
                        <Button sx={styles.textPortrait} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO4"
                        }}>
                            L4
                        </Button>
                    </Box>
                    <Box
                        sx={{height: '15vh', width: '17.5%', background: '#FFFFFF', borderRadius:'15px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                        <Button sx={styles.textPortrait} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO5"
                        }}>
                            L5
                        </Button>
                    </Box>
                </Box>
                <Box sx={{display:'flex', flexDirection:'row', justifyContent: 'space-between'}}>
                <Box sx={styles.whiteBoxPortrait}>
                    <Button sx={styles.textPortrait} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO6"
                        }}>
                            L6
                        </Button>
                    </Box>
                    <Box sx={styles.whiteBoxPortrait}>
                        <Button sx={styles.textPortrait} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO7"
                        }}>
                            L7
                        </Button>
                    </Box>
                    <Box sx={styles.whiteBoxPortrait}>
                        <Button sx={styles.textPortrait} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO8"
                        }}>
                            L8
                        </Button>
                    </Box>
                    <Box sx={styles.whiteBoxPortrait}>
                        <Button sx={styles.textPortrait} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO9"
                        }}>
                            L9
                        </Button>
                    </Box>
                    <Box
                        sx={{height: '15vh', width: '17.5%', background: '#FFFFFF', borderRadius: '15px',  display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                        <Button sx={styles.textPortrait} onClick={()=>{
                            window.location.href = "/players/law-and-order/play/LO10"
                        }}>
                            L10
                        </Button>
                    </Box>
                </Box>
    
                <Box sx={{ background:'#001C50', display:'flex', flexDirection:'row', justifyContent: 'space-between'}}>
                </Box>
    
            </Container>
        )
    }
}

const styles = {
    headerBox : {
        borderRadius: '15px',
        height: '23vh',
        width: '100%',
        background: '#50AFCD',
        mb:'15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize:{xs:'35pt',  md: '55pt'},
    },
    headerBoxPortrait : {
        borderRadius: '15px',
        height: '15vh',
        width: '100%',
        background: '#50AFCD',
        mb:'15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20pt'
    },
    whiteBox: {
        height: '24vh',
        width: '20%',
        background: '#FFFFFF',
        mr: '15px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    whiteBoxPortrait: {
        height: '15vh',
        width: '20%',
        background: '#FFFFFF',
        mr: '5px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        fontSize: {xs:'35pt',  md: '55pt'},
        color: '#001C50',
        height:'50px'
    },
    textPortrait: {
        fontSize: '25pt',
        color: '#001C50',
        height:'50px'
    }
}


export default Selection