import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import useAlert from "src/helpers/useAlert";
import useModal from "src/helpers/useModal";
import {
    Button,
    Box,
    FormControl,
    TextField,
    Typography,
} from "@mui/material";

import { requestPasswordResetEmail } from 'src/services/authServices';
import { verifyProbationer } from "src/services/probationerServices";

import { IcCheck, IcStopSign } from "src/assets/icons";

const validationSchema = yup.object({
    email: yup
        .string("Enter officer email")
        .email("Enter a valid email")
        .required("Officer email is required")
});

const PasswordResetPopup = (props) => {
    const { action } = props;
    const { closeModal } = useModal();
    const { setAlert } = useAlert();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // console.log(values);
            // call request password-reset email api
            const response = await requestPasswordResetEmail({ email: values.email });
            // console.log('response: ', response);
            if (response.data.success) {
                setAlert({
                    icon: IcCheck,
                    title: "Request Successful",
                    description: "Check your email for the link to reset your password.",
                    buttonText: "OK",
                    type: "OK",
                });
                closeModal();
            } else {
                setAlert({
                    icon: IcStopSign,
                    title: "Request Failed",
                    description: response.data.error,
                    buttonText: "OK",
                    type: "OK",
                });
            }
        },
    });
    return (
        <>
            <Typography align="center" fontSize="14pt" sx={{ color: "#000000" }}>
                {props.special ? props.special : "To reset your password, please enter your email address."}
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <FormControl sx={{ my: 1, width: { xs: "234px", md: "345px" }, }}>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label={
                                formik.values.email === "" ?
                                    "Email address"
                                    : ""
                            }
                            InputLabelProps={{ shrink: false, sx: { color: "#0" } }}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            sx={{ maxWidth: "488px", color: "blue" }}
                        />
                    </FormControl>
                    <Button
                        color="green"
                        variant="contained"
                        type="submit"
                        sx={{ fontSize: "14pt", width: { xs: "234px", md: "345px" } }}
                    >
                        RESET PASSWORD
                    </Button>
                </Box>
            </form>
        </>
    );
};

export default PasswordResetPopup;
