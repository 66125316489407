import React from 'react';
import * as yup from "yup";
import { useFormik } from "formik";
import {
    Button,
    Box,
    Divider,
    TextField,
    Typography
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";

import { useState, useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';
import useAlert from "src/helpers/useAlert";
import useModal from "src/helpers/useModal";

import { getProbationerProfile, postProbationer } from 'src/services/probationerServices';
import { getVILODates } from 'src/services/officerServices';

import { IcStopSign, IcCheck } from "src/assets/icons";
import PasswordResetPopup from 'src/pages/auth/PasswordResetPopup';
import { useLocation } from 'react-router';
import { getPasswordHistory } from 'src/services/authServices';

const validationSchema = yup.object({
    name: yup
        .string("Enter name")
        .required("Name is required"),
    probationerId: yup
        .string("Enter name")
        .required("Name is required"),
    crn: yup
        .string("Enter Case Reference Number")
        .required("Case Reference Number is required"),
    email: yup
        .string("Enter client email")
        .email("Enter a valid email")
        .required("Client email is required"),
    activation: yup.boolean(),
});

const PlayerDashboardProfile = () => {
    const [profileData, setProfileData] = useState({});
    const [viloDates, setviloDates] = useState({ startdate: null, enddate: null });
    const { setAuth, auth } = useAuth();
    const { setAlert } = useAlert();
    const { setModal } = useModal();

    const { state } = useLocation();

    useEffect(() => {
        if (!state) return
        setModal(
            <>
                <PasswordResetPopup />
            </>
        )
    }, [])




    useEffect(() => {
        const fetchData = async () => {
            const datesRes = await getVILODates(auth.probationer.probationerId);
            const response = await getProbationerProfile(auth.probationer.probationerId);

            if (datesRes.data.success) {
                if (!(datesRes.data.value == null)) {
                    setviloDates(datesRes.data.value);
                }
            }
            setProfileData(response.data.value);
        }
        fetchData();

        const fetchHistory = async () => {
            let firstLogin = localStorage.getItem('firstLogin');
            if (firstLogin !== '1') return
            const histRes = await getPasswordHistory();
            if (histRes.data.success) {
                if (histRes.data.value.length === 0) {
                    setModal(
                        <>
                            <PasswordResetPopup special={"Password should be changed on first login. To change your password, please enter your email address."}/>
                        </>
                        , '462px');
                    localStorage.setItem('firstLogin', '0')
                }
            }
        }
        fetchHistory();


    }, [])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: auth?.probationer?.name || "",
            probationerId: profileData?.probationerId || "",
            crn: profileData?.crn || "",
            email: profileData?.username || "",
            startdate: profileData?.startdate || null,
            enddate: profileData?.enddate || null,
            activation: true,
            officerId: profileData?.officer?.officerId || '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // console.log('values: ', values);
            const postResponse = await postProbationer(values);
            if (postResponse.data.success) {
                const profileResponse = await getProbationerProfile(postResponse.data.value.probationerId);
                const newProfileData = structuredClone(profileResponse.data.value)
                newProfileData.probationer = { name: formik.values.name, probationerId: formik.values.probationerId }
                // console.log('new profile data: ', newProfileData);
                setAuth(newProfileData);
                setProfileData(newProfileData);
                setAlert({
                    icon: IcCheck,
                    title: "Success",
                    description: "Your profile has been updated.",
                    buttonText: "OK",
                    type: "OK",
                });
            } else {
                setAlert({
                    icon: IcStopSign,
                    title: "Error",
                    description: postResponse.data.error,
                    buttonText: "OK",
                    type: "OK",
                });
            }
        }
    });

    return (
        <div>
            <Typography variant="h2" color="secondary"
                sx={{ ml: { md: "20px" }, fontSize: "32px" }}
            >
                Profile
            </Typography>
            <Divider sx={{ mt: "15px", mb: "15px", width: "0px" }} />

            <Box sx={{ display: "flex", flexDirection: "column", background: "", ml: { md: "20px" } }}>
                <Typography variant="label">
                    Your Name { }
                    <Box fontWeight='fontWeightMedium' display='inline'>
                        (You can give yourself a nickname)
                    </Box>
                </Typography>
                <TextField
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    id='name'
                    name='name'
                    variant="outlined"
                    placeholder="Name"
                    size="small"
                    fullWidth
                    sx={{ maxWidth: "400px" }} />

                <Divider sx={{ mt: "15px", mb: "15px", width: "0px" }} />

                <Typography variant="label">
                    Your Email
                </Typography>
                <TextField
                    // onChange={formik.handleChange}
                    value={formik.values.email}
                    disabled={true}
                    id='email'
                    name='email'
                    variant="outlined"
                    placeholder='Email'
                    // placeholder={auth.email}
                    size="small"
                    fullWidth
                    sx={{ background: "#F5F5F5", maxWidth: "400px" }} />

                <Divider sx={{ mt: "15px", mb: "15px", width: "0px" }} />

                <Typography variant="label">
                    VI & LO Start Date
                </Typography>
                <MobileDatePicker
                    // value={formik.values.startdate}
                    value={viloDates.startdate}
                    disabled={true}
                    renderInput={(params) => (
                        <TextField
                            sx={{ background: "#F5F5F5", maxWidth: "400px" }}
                            variant='outlined'
                            placeholder={Object.keys(profileData).length === 0 ? "" : profileData.startdate}
                            error={Boolean(
                                formik.touched.startdate &&
                                formik.errors.startdate
                            )}
                            helperText={
                                formik.touched.startdate &&
                                formik.errors.startdate
                            }
                            name="startdate"
                            size="small"
                            InputLabelProps={{ shrink: false }}
                            {...params}
                        />
                    )}
                />
                <Divider sx={{ mt: "15px", mb: "15px", width: "0px" }} />

                <Typography variant="label">
                    VI & LO End Date
                </Typography>

                <MobileDatePicker
                    value={viloDates.enddate}
                    disabled={true}
                    renderInput={(params) => (
                        <TextField
                            sx={{ background: "#F5F5F5", maxWidth: "400px" }}
                            variant='outlined'
                            placeholder={Object.keys(profileData).length === 0 ? "" : profileData.enddate}
                            error={Boolean(
                                formik.touched.enddate &&
                                formik.errors.enddate
                            )}
                            helperText={
                                formik.touched.enddate &&
                                formik.errors.enddate
                            }
                            name="enddate"
                            size="small"
                            InputLabelProps={{ shrink: false }}
                            {...params}
                        />
                    )}
                />
            </Box>

            <Divider sx={{ mt: "30px", mb: "30px", heihgt: "5px", width: "100%" }} />

            <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "right", gap: 10 } }}>
                <button
                    style={{ background: "none", border: "none", outline: "none", fontSize: 15, textDecoration:"underline" }}
                    type='none'
                    onClick={async () => {
                        setModal(
                            <>
                                <PasswordResetPopup />
                            </>
                            , '462px');
                    }}
                >
                    Reset Password
                </button>
                <Button
                    sx={{ width: "153px", height: "39px", fontSize: "16px", marginRight: { md: "80px" } }}
                    variant="rounded"
                    size="small"
                    // type='submit'
                    onClick={() => {
                        formik.handleSubmit();
                    }}
                >
                    SAVE
                </Button>
            </Box>
        </div>
    )
}

export default PlayerDashboardProfile
