import { React, useEffect, useState } from 'react'
import { Button, Box, Card, CardMedia, Divider, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom"
import useModal from "src/helpers/useModal";
import useAlert from 'src/helpers/useAlert';
import useSession from 'src/hooks/useSession';
import useAuth from 'src/hooks/useAuth';

import { getVISectionResources } from 'src/services/victimimpactServices';

import { IcPeoplesBlack, IcCheck, IcStopSign } from "src/assets/icons";

import DraggableCard from "src/components/DraggableCard";
import CardReceiver from "src/components/CardReceiver";
import Header from '../../../../components/Header';
import AnswersSavedPopup from '../../popups/AnswersSavedPopup';

import ReactHlsPlayer from '@ducanh2912/react-hls-player';

// import withScrolling from 'react-dnd-scrolling';

import {
    getActiveSession,
    postVictimImpactResponses,
    endVictimImpactSession,
    getDraftAnswers,
    getVictimImpactProgress
} from 'src/services/victimimpactServices';

const TypesOfVictims2 = () => {
    const navigate = useNavigate();
    const { setModal } = useModal();
    const { setAlert } = useAlert();
    const { session, setSession } = useSession();
    const { auth, setAuth } = useAuth();
    const [count, setCount] = useState(0);

    const [resources, setResources] = useState([{ videourl: '' }]);
    const [answers, setAnswers] = useState([
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
    ])

    const options = [
        "Youth",
        "Elderly lady",
        "Elderly man",
        "Children",
        "Children’s parents",
        "People at the hawker centre",
        "Residents living in the neighbourhood",
    ];

    const handleMoveDraggable = (receiverId, id) => {
        let newAnswers = answers.slice();
        newAnswers[receiverId] = id;
        setAnswers(newAnswers);
        if (answers.includes(id)) {
            newAnswers[answers.indexOf(id)] = undefined;
            newAnswers[receiverId] = id;
            setAnswers(newAnswers);
        } else {
            newAnswers[receiverId] = id;
            setAnswers(newAnswers);
        }
    }

    const handleSubmit = async () => {
        if (answers.includes(undefined)) {
            setAlert({
                title: "Error",
                icon: IcStopSign,
                description: "Do not leave any blanks.",
                buttonText: "OK",
                type: "OK"
            });
            return
        }
        let response = { primary: '', secondary: [], indirect: [] };
        response.primary = options[answers[0]];
        response.secondary.push(options[answers[1]]);
        response.secondary.push(options[answers[3]]);
        response.secondary = response.secondary.toString();
        response.indirect.push(options[answers[2]]);
        response.indirect.push(options[answers[4]]);
        response.indirect = response.indirect.toString();
        let postObj = {};
        postObj.response = response;
        postObj.isDraft = 0;
        const res = await postVictimImpactResponses(2, session.ToV_sessionId, postObj);
        if (res.data.success) {
            setAlert({
                title: "Success",
                icon: IcCheck,
                description: "Answers have been successfully submitted.",
                buttonText: "OK",
                type: "OK"
            });
            const res = await endVictimImpactSession(session.ToV_sessionId);
            // Get Sidebar VI Progress
            const viProgress = await getVictimImpactProgress(auth.probationer.probationerId);
            const newSession = structuredClone(session);
            newSession.VI_progress = viProgress.data.value.VI_progress;
            await setSession(newSession);
            await setAnswers([undefined, undefined, undefined, undefined, undefined]);
            navigate('/players/victim-impact/types-of-hurts/1');
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setCount(count + 1);
            const response = await getVISectionResources(2);
            setResources(response.data.value);
            // Get ToV sessionId
            const res = await getActiveSession(auth?.probationer?.probationerId, 2);
            if (res.data.success) {
                const newSession = structuredClone(session);
                newSession.ToV_sessionId = res.data.value;
                await setSession(newSession);
            }
        }
        if (count < 1) {
            // console.log('count: ', count);
            fetchData();
        }
    }, []);

    useEffect(() => {
        // console.log('session change: ', session);
        const fetchDraft = async () => {
            if ('ToV_sessionId' in session) {
                const res = await getDraftAnswers(2, session.ToV_sessionId);
                // console.log('RES: ', res.data.value);
                if (res.data.value.length !== 0) {
                    const draft = res.data.value;
                    const newAnswers = structuredClone(answers);
                    newAnswers[0] = options.indexOf(draft.primary);
                    newAnswers[1] = options.indexOf(draft.secondary.split(',')[0])
                    newAnswers[2] = options.indexOf(draft.secondary.split(',')[1])
                    newAnswers[3] = options.indexOf(draft.indirect.split(',')[0])
                    newAnswers[4] = options.indexOf(draft.indirect.split(',')[1])
                    // console.log('newAnswers: ', newAnswers);
                    setAnswers(newAnswers);
                }
            }
        }
        fetchDraft();
    }, [session]);

    

    return (
        <>
            <Header
                title='Types of Victims'
                text='Match the characters to the type of victim. Drag and drop the different characters into the correct boxes.'
                icon={IcPeoplesBlack}
                iconSize='70%' />

            <Divider sx={{ mt: { xs: '10px', md: "0px" }, mb: { xs: "20px", md: "40px" } }} />

            <Box sx={{
                // background: {xs: "pink", sm: 'lightblue', md: "lightgreen"},
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                alignItems: "center",
                fontSize: "20px",
                height: { xs: "1420px", sm: '100%', md: "1750px" },
                width: { xs: "100%" },
            }}
                maxWidth="false">

                <Box>
                    <Typography sx={{ fontSize: "20px" }}>
                        Re-enactment of crime scene
                    </Typography>
                </Box>
                <Box
                    sx={{ background: '', width: { xs: "100%", md: "720px" }, height: { xs: "300px%", md: "405px" }, mb: '50px' }}
                >
                    <ReactHlsPlayer
                        src={resources[0]?.videourl}
                        autoPlay={false}
                        controls={false}
                        width="100%"
                    />
                </Box>
                <Typography sx={{ fontSize: "20px" }}>
                    Identify victims in the scene
                </Typography>
                <Typography textAlign="center" sx={{ p: { xs: 1 }, mb: "15px" }}>
                    Drag & drop the characters from the scene into the category of victims you think they belong to.
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "center", width: { xs: "75%", md: "35%" } }}>
                    <img
                        style={styles.victimRipple}
                        src={require("src/assets/illustration/Victims-Ripple.png").default}
                        alt="Victims Ripple"
                    />
                </Box>

                {/* >md answers */}
                <Box sx={{ width: '85%', background: '', display: { xs: "none", md: 'flex' }, justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>

                    <Box sx={{ background: '', height: "90%", display: { xs: "none", md: 'flex' }, flexDirection: "column", alignItems: "center", padding: "5px", ml: "15px" }}>
                        <Box sx={{ background: '', width: "300px", height: "75px", display: "flex", alignItems: "center", cursor: "help" }} fontWeight={"bold"}>
                            <span title="Direct victims of incident">Primary Victims</span>
                            {window.innerHeight > window.innerWidth &&<p style={{fontSize:12, fontStyle:"italic"}}>Direct victims of incident</p>}
                            {/* Primary Victim */}
                        </Box>
                        <Box sx={{ background: '', width: "300px", height: "75px", display: "flex", alignItems: "center", cursor: "help" }} fontWeight={"bold"}>
                            <span title="Not directly affected but are related to the primary victim">Secondary Victims</span>
                            {window.innerHeight > window.innerWidth &&<p style={{fontSize:12, fontStyle:"italic"}}>Not directly affected but are related to the primary victim</p>}
                            {/* Secondary Victim */}
                        </Box>
                        <Box sx={{ background: '', width: "300px", height: "75px", display: "flex", alignItems: "center", cursor: "help" }} fontWeight={"bold"}>
                            <span title="Unrelated to the incident but are also affected by the offence">Hidden/Indirect Victims</span>
                            {window.innerHeight > window.innerWidth &&<p style={{fontSize:12, fontStyle:"italic"}}>Unrelated to the incident but are also affected by the offence</p>}
                            {/* Hidden/Indirect Victims */}
                        </Box>
                    </Box>

                    <Box sx={{ background: '', height: "100%", display: "", flexDirection: "row", justifyContent: "", alignItems: "center", padding: "5px" }}>
                        <Box sx={{ background: '', width: "100%", height: "75px", display: "flex", alignItems: "center", flex: "1 1 auto", margin: "5px" }}>
                            <CardReceiver id={answers[0]} content={options[answers[0]]} receiverId='0' handleMoveDraggable={handleMoveDraggable} />
                        </Box>
                        <Box sx={{ background: '', width: "100%", height: "75px", display: "flex", alignItems: "center", flex: "1 1 auto", margin: "5px" }}>
                            <CardReceiver id={answers[1]} content={options[answers[1]]} receiverId='1' handleMoveDraggable={handleMoveDraggable} />
                        </Box>
                        <Box sx={{ background: '', width: "100%", height: "75px", display: "flex", alignItems: "center", flex: "1 1 auto", margin: "5px" }}>
                            <CardReceiver id={answers[2]} content={options[answers[2]]} receiverId='2' handleMoveDraggable={handleMoveDraggable} />
                        </Box>
                    </Box>

                    <Box sx={{ background: '', display: "flex", marginTop: "auto", justifyContent: "flex-end", flexDirection: "column", alignItems: "center", padding: "5px" }}>
                        <Box sx={{ background: '', width: "100%", height: "75px", display: "flex", alignItems: "center", flex: "1 1 auto", margin: "5px" }}>
                            <CardReceiver id={answers[3]} content={options[answers[3]]} receiverId='3' handleMoveDraggable={handleMoveDraggable} />
                        </Box>
                        <Box sx={{ background: '', width: "100%", height: "75px", display: "flex", alignItems: "center", flex: "1 1 auto", margin: "5px" }}>
                            <CardReceiver id={answers[4]} content={options[answers[4]]} receiverId='4' handleMoveDraggable={handleMoveDraggable} />
                        </Box>
                    </Box>

                </Box>

                {/* sm answers */}
                <Box sx={{ width: '100%', background: '', display: { xs: "none", sm: 'flex', md: 'none' }, justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
                    <Box sx={{ background: '', width: "100%", height: "90%", flexDirection: "column", alignItems: "center", padding: "5px", ml: "10px" }}>
                        <Box sx={{ background: '', width: "100%", height: "75px", display: "flex", alignItems: "center" }} fontWeight={"bold"}>
                            <span title="Direct victims of incident">Primary Victim</span>
                            {window.innerHeight > window.innerWidth &&<p style={{fontSize:12, fontStyle:"italic"}}>Direct victims of incident</p>}
                            {/* Primary Victim */}
                        </Box>
                        <Box sx={{ background: '', width: "180px", height: "75px", display: "flex", alignItems: "center" }} fontWeight={"bold"}>
                            <span title="Not directly affected but are related to the primary victim">Secondary Victim</span>
                            {window.innerHeight > window.innerWidth &&<p style={{fontSize:12, fontStyle:"italic"}}>Not directly affected but are related to the primary victim</p>}
                            {/* Secondary Victim */}
                        </Box>
                        <Box sx={{ background: '', width: "180px", height: "75px", display: "flex", alignItems: "center" }} fontWeight={"bold"}>
                            <span title="Unrelated to the incident but are also affected by the offence">Hidden/Indirect Victims</span>
                            {window.innerHeight > window.innerWidth &&<p style={{fontSize:12, fontStyle:"italic"}}>Unrelated to the incident but are also affected by the offence</p>}
                            Hidden/Indirect Victims
                        </Box>
                    </Box>
                    <Box sx={{ background: '', height: "100%", width: '100%', display: "", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "5px" }}>
                        <Box sx={{ background: '', width: "100%", height: "75px", display: "flex", justifyContent: "center", alignItems: "center", flex: "1 1 auto", margin: "5px" }}>
                            <CardReceiver width={'.3' * window.screen.width + 'px'} id={answers[0]} content={options[answers[0]]} receiverId='0' handleMoveDraggable={handleMoveDraggable} />
                        </Box>
                        <Box sx={{ background: '', width: "100%", height: "75px", display: "flex", justifyContent: "center", alignItems: "center", flex: "1 1 auto", margin: "5px" }}>
                            <CardReceiver width={'.3' * window.screen.width + 'px'} id={answers[1]} content={options[answers[1]]} receiverId='1' handleMoveDraggable={handleMoveDraggable} />
                        </Box>
                        <Box sx={{ background: '', width: "100%", height: "75px", display: "flex", justifyContent: "center", alignItems: "center", flex: "1 1 auto", margin: "5px" }}>
                            <CardReceiver width={'.3' * window.screen.width + 'px'} id={answers[2]} content={options[answers[2]]} receiverId='2' handleMoveDraggable={handleMoveDraggable} />
                        </Box>
                    </Box>

                    <Box sx={{ background: '', display: "flex", width: '100%', marginTop: "auto", justifyContent: "flex-end", flexDirection: "column", alignItems: "center", padding: "5px" }}>
                        <Box sx={{ background: '', width: "100%", height: "75px", display: "flex", justifyContent: "center", alignItems: "center", flex: "1 1 auto", margin: "5px" }}>
                            <CardReceiver width={'.3' * window.screen.width + 'px'} id={answers[3]} content={options[answers[3]]} receiverId='3' handleMoveDraggable={handleMoveDraggable} />
                        </Box>
                        <Box sx={{ background: '', width: "100%", height: "75px", display: "flex", justifyContent: "center", alignItems: "center", flex: "1 1 auto", margin: "5px" }}>
                            <CardReceiver width={'.3' * window.screen.width + 'px'} id={answers[4]} content={options[answers[4]]} receiverId='4' handleMoveDraggable={handleMoveDraggable} />
                        </Box>
                    </Box>

                </Box>

                {/* xs answers */}
                <Box sx={{ background: '', display: { xs: "flex", sm: "none" }, flexDirection: "row", width: "300px", }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ background: '', width: "300px", display: "flex", alignItems: "flex-start", flexDirection:"column" }} fontWeight={"bold"}>
                            Primary Victim
                            {window.innerHeight > window.innerWidth &&<p style={{fontSize:12, fontStyle:"italic"}}>Direct victims of incident</p>}
                        </Box>
                        <CardReceiver id={answers[0]} content={options[answers[0]]} receiverId='0' handleMoveDraggable={handleMoveDraggable} />


                        <Box sx={{ background: '', width: "300px", display: "flex", alignItems: "flex-start", flexDirection:"column" }} fontWeight={"bold"}>
                            Secondary Victim
                            {window.innerHeight > window.innerWidth &&<p style={{fontSize:12, fontStyle:"italic"}}>Not directly affected but are related to the primary victim</p>}
                        </Box>
                        <CardReceiver id={answers[1]} content={options[answers[1]]} receiverId='1' handleMoveDraggable={handleMoveDraggable} />
                        <CardReceiver id={answers[2]} content={options[answers[2]]} receiverId='2' handleMoveDraggable={handleMoveDraggable} />



                        <Box sx={{ background: '', width: "300px", display: "flex", alignItems: "flex-start", flexDirection:"column" }} fontWeight={"bold"}>
                            Hidden/Indirect Victims
                            {window.innerHeight > window.innerWidth &&<p style={{fontSize:12, fontStyle:"italic"}}>Unrelated to the incident but are also affected by the offence</p>}
                        </Box>
                        <CardReceiver id={answers[3]} content={options[answers[3]]} receiverId='3' handleMoveDraggable={handleMoveDraggable} />
                        <CardReceiver id={answers[4]} content={options[answers[4]]} receiverId='4' handleMoveDraggable={handleMoveDraggable} />
                    </Box>
                </Box>


                {/* >sm options */}
                {/* <Box sx={{ width: '80%', background: '', display: { xs: "none", sm: 'flex' }, justifyContent: "space-between", alignItems: "center", flexDirection: "column", mt: "50px" }}>
                    <Box sx={{ width: "100%", background: '', display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", padding: "5px" }}>
                        <DraggableCard id={0} text={options[0]} css={styles.whitebox} />
                        <DraggableCard id={1} text={options[1]} css={styles.whitebox} />
                        <DraggableCard id={2} text={options[2]} css={styles.whitebox} />
                    </Box>
                    <Box sx={{ width: "100%", background: '', display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", padding: "5px" }}>
                        <DraggableCard id={3} text={options[3]} css={styles.whitebox} />
                        <DraggableCard id={4} text={options[4]} css={styles.whitebox} />
                        <DraggableCard id={5} text={options[5]} css={styles.whitebox} />
                    </Box>
                    <Box sx={{ width: "100%", background: '', display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", padding: "5px" }}>
                        <DraggableCard id={6} text={options[6]} css={styles.whitebox} />
                    </Box>
                </Box> */}

                {/* >xs options */}
                <Box sx={{
                    background: '',
                    // display: { xs: "flex", sm: "none" },
                    display: "flex",
                    flexDirection: window.innerWidth < 768 ? "column": "row", width: "300px", justifyContent: "center", alignItems: "center", padding: "15px", 
                    mb: "50px"
                }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <DraggableCard id={0} text={options[0]} css={styles.whitebox} />
                        <br />
                        <DraggableCard id={1} text={options[1]} css={styles.whitebox} />
                        <br />
                        <DraggableCard id={2} text={options[2]} css={styles.whitebox} />
                        <br />
                    </Box>
                    <Divider orientation='vertical' sx={{ margin: "10px", height: "0" }} />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <DraggableCard id={3} text={options[3]} css={styles.whitebox} />
                        <br />
                        <DraggableCard id={4} text={options[4]} css={styles.whitebox} />
                        <br />
                        <DraggableCard id={5} text={options[5]} css={styles.whitebox} />
                        <br />
                    </Box>
                    <Divider orientation='vertical' sx={{ margin: "10px", height: "0" }} />
                    <Box sx={{ display: "flex", flexDirection: "column", }}>
                        <DraggableCard id={6} text={options[6]} css={styles.whitebox} />
                    </Box>
                </Box>

                <Divider sx={{ width: '0', height: "0", mb: { xs: "20px", md: "40px" } }} />

                <Box sx={{ background: '', display: 'flex', width: { md: '52.5%' }, background: "", justifyContent: { xs: "center", md: "space-between" }, flexDirection: { xs: "column", md: "row" }, alignItems: "center", mb: { xs: "30px" } }}>
                    <Button
                        sx={{ background: 'white', color: "#4FAFCD", border: '1px solid #4FAFCD', fontSize: "16px", width: "239px", height: "39px", mb: { xs: "10px", md: "0" } }}
                        variant="rounded"
                        fullWidth
                        size="small"
                        onClick={async () => {
                            let response = { primary: '', secondary: [], indirect: [] };
                            response.primary = options[answers[0]];
                            response.secondary.push(options[answers[1]]);
                            response.secondary.push(options[answers[3]]);
                            response.secondary = response.secondary.toString();
                            response.indirect.push(options[answers[2]]);
                            response.indirect.push(options[answers[4]]);
                            response.indirect = response.indirect.toString();
                            let postObj = {};
                            postObj.response = response;
                            postObj.isDraft = 1;
                            // console.log('POSTOBJ: ', postObj);
                            const res = await postVictimImpactResponses(2, session.ToV_sessionId, postObj);
                            // console.log('RESPONSE: ', res);
                            if (res.data.success) {
                                setModal(
                                    <>
                                        <AnswersSavedPopup />
                                    </>
                                    , '462px');
                            } else {
                                setAlert({
                                    title: "Error",
                                    icon: IcStopSign,
                                    description: "Answers have not been submitted.",
                                    buttonText: "OK",
                                    type: "OK"
                                });
                            }
                        }}
                    >
                        SAVE & CONTINUE LATER
                    </Button>
                    <Button
                        sx={{ fontSize: "16px", width: "239px", height: "39px" }}
                        variant="rounded"
                        fullWidth
                        size="small"
                        onClick={handleSubmit}
                    >
                        SUBMIT
                    </Button>

                    <div style={{ height: "300px" }} />
                </Box>
            </Box>
        </>
    )
}

const styles = {
    dndBox: {
        position: "relative",
        textAlign: "left",
        right: "0px",
        mx: "auto",
        borderRadius: "20px",
        maxWidth: "1100px",
        padding: "15px 5px",
    },
    victimRipple: {
        position: "relative",
        paddingTop: "10px",
        paddingBottom: "10px",
        width: "100%",
    },
    whitebox: {
        display: "flex",
        alignItems: "center",
        background: "var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #AAAAAA",
        borderRadius: "8px",
        opacity: 1,
        width: "300px",
        height: "75px",
    }
}

export default TypesOfVictims2